import { PresaveSource, ReleasePresave } from '@max/common/artists/presave';
import { SetPageRelease } from '@max/common/setpage';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

type Service = SetPageRelease['preSaveOn'][0];

interface Context {
  releaseEndpoint?: string;
  userId?: string;
  authorizeSpotify?: (releaseId: string, source: PresaveSource) => void;
  savingRelease?: Service | null;
  setSavingRelease?: Dispatch<SetStateAction<Service | null>>;
  savedRelease?: Service | null;
  presaveApple?: (
    code: string,
    releaseId: string,
    source: PresaveSource,
  ) => Promise<void>;
  preSaves?: ReleasePresave[];
  preSavesLoading?: boolean;
  optInAllPreSaves?: (releaseId: string, preSave: ReleasePresave) => void;
  spotify?: {
    authEndpoint: string;
    clientId: string;
    workerEndpoint: string;
  };
  apple?: {
    musicKitCdn: string;
    workerEndpoint: string;
  };
}

const PreSaveContext = createContext<Context>({});

type PreSaveProviderProps = {
  children: React.ReactNode;
} & Pick<
  Context,
  | 'userId'
  | 'preSaves'
  | 'preSavesLoading'
  | 'optInAllPreSaves'
  | 'spotify'
  | 'apple'
  | 'releaseEndpoint'
>;

const SPOTIFY_SCOPES = [
  'user-read-email',
  'user-read-recently-played',
  'user-top-read',
  'user-library-read',
  'user-library-modify',
];

export const PreSaveProvider = ({
  children,
  preSaves,
  preSavesLoading,
  optInAllPreSaves,
  userId,
  spotify,
  apple,
  releaseEndpoint,
}: PreSaveProviderProps) => {
  const [savingRelease, setSavingRelease] = useState<Service | null>(null);
  const [savedRelease, setSavedRelease] = useState<Service | null>(null);

  const authorizeSpotify = (releaseId: string, source: PresaveSource) => {
    if (!spotify) return;

    setSavingRelease('spotify');
    const authUrl = new URL(spotify.authEndpoint);
    authUrl.searchParams.set('client_id', spotify.clientId);
    authUrl.searchParams.set('redirect_uri', spotify.workerEndpoint);
    authUrl.searchParams.set('response_type', 'code');
    authUrl.searchParams.set('scope', SPOTIFY_SCOPES.join(','));
    authUrl.searchParams.set(
      'state',
      JSON.stringify({
        uid: userId,
        releaseId,
        redirectUri: encodeURIComponent(window.location.href),
        source,
      }),
    );
    window.location.href = authUrl.toString();
  };

  const checkForSpotifyPreSave = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const isPresaved = searchParams.get('spotify_presaved');
    if (isPresaved === 'true') {
      const url = new URL(window.location.toString());
      url.searchParams.delete('spotify_presaved');
      window.history.replaceState({}, document.title, url.toString());
      setSavedRelease('spotify');
      setSavingRelease(null);
    }
  };

  useEffect(() => {
    checkForSpotifyPreSave();
    document.addEventListener('musickitloaded', async () => {
      if (!apple) return;

      try {
        const response = await fetch(apple?.workerEndpoint, {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({ action: 'token' }),
        });
        const responsePayload: { token?: string } | undefined =
          await response.json();
        // apple docs show this as async, types do not. be safe
        await window.MusicKit.configure({
          developerToken: responsePayload?.token,
          app: {
            name: 'SETBio',
            build: '1',
          },
        });
      } catch (err) {
        console.error('there was a problem calling MusicKit.configure', err);
      }
    });
  }, [apple]);

  const presaveApple = async (
    code: string,
    releaseId: string,
    source: PresaveSource,
  ) => {
    if (!apple) return;

    try {
      await fetch(apple.workerEndpoint, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          code,
          releaseId,
          redirectUri: '',
          uid: userId,
          source,
        }),
      });
    } catch (err) {
      console.error(err);
    }
  };

  const value = {
    authorizeSpotify,
    savingRelease,
    setSavingRelease,
    savedRelease,
    presaveApple,
    preSaves,
    preSavesLoading:
      preSavesLoading === undefined && optInAllPreSaves
        ? true
        : preSavesLoading,
    optInAllPreSaves,
    spotify,
    releaseEndpoint,
    apple,
  };

  return (
    <PreSaveContext.Provider value={value}>{children}</PreSaveContext.Provider>
  );
};

export const usePreSaveContext = () => useContext(PreSaveContext);
